import * as React from 'react'
import styled from 'styled-components'


export default function ButtonC(props) {
    return (
        <ButtonStyle  
        disabled={props.isDisabled}
        className={props.className} 
        disabled={props.selected} 
        onTouchStart={props.onTouchStart(props.scene)}
        ><img src={props.src} width="80%"/></ButtonStyle>
    );
  }

const ButtonStyle = styled.button`
  &:disabled {
    background: ${({ theme }) => theme.color.disable};
  }
`
