import * as React from 'react'
import styled from 'styled-components'


export default function ButtonB(props) {
    return (
        <ButtonStyle  
          disabled={props.isDisabled}
          className={props.className} 
          onTouchStart={props.onTouchStart(props.scene)} 
          width={props.width}
          height={props.height}>
            {props.children}
        </ButtonStyle>
    );
  }

const ButtonStyle = styled.button`
  width: ${({ width }) => width ? width : "90%"};
  height: ${({ height }) => height ? height : "80px"};
  margin: 5px 5px;
  font-size: 2em;
  font-family: ${({ theme }) => theme.font.bold};
  background: ${({ theme }) => theme.color.key};
  padding: 0 27px;
  border-radius: 10px;
  color: ${({ theme }) => theme.color.white};
  &:disabled {
    background: ${({ theme }) => theme.color.disable};
  }
`
