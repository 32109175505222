export function addComma(num) {
    return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  
  // 1. cmに直す（測定値/10)
  // 2. 小数点第2位を四捨五入して小数点第1位まで表示
  export function formatSize(num) {
    const formatted = Math.round(Math.round(num * 10) / 10) / 10
    return formatted.toFixed(1)
  }
  
  // 小数点第2を四捨五入
  export function formatAncleAngle(num) {
    const formatted = Math.round(Math.round(num * 10)) / 10
    return formatted.toFixed(1)
  }